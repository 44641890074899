import type { PropsWithChildren } from 'react';
import type { StyleVariant } from '../../../../utilities/graphql/codegen';
import type { RecentlyViewedProduct } from '../../../../utilities/helpers';
import { colors } from '../../core/styles';
import { Typography } from '../../core/typography/Typography';
import { S } from './styles';

interface TilePriceDoubleStrikethroughProps {
  singleProduct: RecentlyViewedProduct;
}

export const TilePriceStrikethrough = ({
  singleProduct,
}: PropsWithChildren<TilePriceDoubleStrikethroughProps>) => {
  const basePrice = singleProduct.price?.formattedValue || singleProduct.formattedBasePrice;
  const fromPrice = singleProduct.fromPrice?.formattedValue || singleProduct.formattedFromPrice;
  const intermediaryPrice =
    singleProduct.intermediaryPrice?.formattedValue ||
    (singleProduct as StyleVariant).formattedIntermediaryPrice;

  const hasFromPrice = !!fromPrice && fromPrice !== basePrice;
  const hasIntermediaryPrice = !!intermediaryPrice && intermediaryPrice !== basePrice;

  return (
    <S.PriceArea>
      {hasFromPrice && (
        <Typography
          variant="subtitle"
          decoration="line-through"
          component="p"
          alignment="center"
          testId="product-tile-before-price"
          data-cs-capture=""
        >
          {fromPrice}
        </Typography>
      )}

      {hasIntermediaryPrice && (
        <Typography
          variant="subtitle"
          decoration="line-through"
          component="p"
          alignment="center"
          testId="product-tile-intermediary-price"
          data-cs-capture=""
        >
          {intermediaryPrice}
        </Typography>
      )}

      <Typography
        variant="h4"
        component="p"
        alignment="center"
        color={hasFromPrice || hasIntermediaryPrice ? colors.LIGHT_GREEN : colors.BLACK}
        testId="product-tile-price"
        data-cs-capture=""
      >
        {basePrice}
      </Typography>
    </S.PriceArea>
  );
};
