/* eslint-disable @typescript-eslint/no-empty-function */
import type { ReactElement, ReactNode, RefObject } from 'react';
import { useState, useEffect, useRef } from 'react';
import type { StyleVariant } from '../../../../utilities/graphql/codegen';
import { LowestPriceDisplay } from '../../../../utilities/graphql/codegen';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { parseVariantToProduct } from '../../../../utilities/parsers';
import { VariantArea } from '../VariantArea';
import type { RecentlyViewedProduct } from '../../../../utilities/helpers';
import { parseFormattedPrice } from '../../../../utilities/helpers';
import { S } from './styles';
import { TileImage } from './TileImage';
import { TilePriceStrikethrough } from './TilePriceStrikethrough';
import { TileFiberRanking } from './TileFiberRanking';
import { TileLegacySignings } from './TileLegacySignings';
import type { TileType } from './types';
import { FEATURE_TOGGLE } from '../../../../utilities/constants';
import { TileSignings } from './TileSignings';
import type { ProductPrice } from '../../../../amplienceTypes/schemas/imported/product-price-schema';
import { TilePriceLowest30Days } from './TilePriceLowest30Days';
import { TileTitleWithPrice } from './TileTitleWithPrice';

interface TileProps {
  signingEnabled: boolean;
  product: RecentlyViewedProduct;
  showVariantSelector: boolean;
  variants: StyleVariant[] | undefined;
  wishlistAction?: ReactNode;
  quickView?: ReactNode;
  onLinkClick?: () => void;
  onVariantLinkClick?: () => void;
  tileType: TileType;
  productPrice?: ProductPrice;
}

export const Tile = ({
  signingEnabled,
  product,
  showVariantSelector,
  variants,
  wishlistAction,
  quickView,
  tileType,
  productPrice,
  onLinkClick = () => {},
  onVariantLinkClick = () => {
    /* silent */
  },
}: TileProps): ReactElement => {
  const [productHovered, setProductHovered] = useState(false);
  const [variantHovered, setVariantHovered] = useState(-1);
  const [singleProduct, setSingleProduct] = useState<RecentlyViewedProduct>(product);
  const wrapperRef: RefObject<HTMLElement> = useRef(null);
  const {
    configuration: { enableDarkBgImages, showStrikethroughPrices, lowestPriceDisplay },
  } = useStaticContext();

  const hasVariantArea = showVariantSelector && variants?.length !== 0;

  const fromPrice = product.fromPrice?.formattedValue ?? product.formattedFromPrice ?? '';
  const basePrice = product.price?.formattedValue ?? product.formattedBasePrice ?? '';

  const parsedFromPrice = parseFormattedPrice(fromPrice);
  const parsedBasePrice = parseFormattedPrice(basePrice);

  const DisplayLowestPriceMap: Record<LowestPriceDisplay, boolean> = {
    [LowestPriceDisplay.Always]: true,
    [LowestPriceDisplay.Never]: false,
    [LowestPriceDisplay.DiscountsOnly]: parsedBasePrice < parsedFromPrice,
  };
  const renderLowestPrice = DisplayLowestPriceMap[lowestPriceDisplay || LowestPriceDisplay.Never];

  useEffect(() => {
    if (variantHovered >= 0 && variants?.length) {
      const parsedProduct = parseVariantToProduct(variants[variantHovered], enableDarkBgImages);

      setSingleProduct(parsedProduct as RecentlyViewedProduct);
    } else {
      setSingleProduct(product);
    }
  }, [variantHovered, product, enableDarkBgImages, variants]);

  if (!singleProduct.url) {
    return <></>;
  }

  return (
    <S.Tile data-testid="product-tile" data-cs-capture="">
      <S.TileLink
        ariaLabel={singleProduct.name || ''}
        href={singleProduct.url}
        testId="product-tile-link"
        data-hover-variant-area={hasVariantArea}
        data-product-code={product.code}
        data-cs-capture=""
        onClick={() => sessionStorage?.setItem('plpNavigatedProduct', product.code)}
      >
        <S.ProductWrapper ref={wrapperRef as RefObject<HTMLDivElement>} onClick={onLinkClick}>
          <TileFiberRanking
            wrapper={S.FiberSigningsWrapper}
            fiberRankingScore={product.fiberRanking?.fiberRanking?.productFiberRankingData?.score}
          />

          <S.WishlistActionWrapper>{wishlistAction}</S.WishlistActionWrapper>

          <S.RelativeElement
            data-testid="product-tile-image"
            data-cs-capture=""
            onMouseEnter={() => setProductHovered(true)}
            onMouseLeave={() => setProductHovered(false)}
          >
            <TileImage
              tileType={tileType}
              singleProduct={singleProduct}
              variantHovered={variantHovered}
              productHovered={productHovered}
            />

            {FEATURE_TOGGLE.NEW_TILE_SIGNINGS ? (
              <S.SigningsArea>
                <TileSignings signingEnabled={signingEnabled} singleProduct={singleProduct} />

                <S.QuickViewWrapper>{quickView}</S.QuickViewWrapper>
              </S.SigningsArea>
            ) : (
              // TODO: proper cleanup + file removal
              <TileLegacySignings
                quickViewAction={<S.QuickViewWrapper>{quickView}</S.QuickViewWrapper>}
                singleProduct={singleProduct}
                signingEnabled={signingEnabled}
              />
            )}
          </S.RelativeElement>

          <S.ProductData>
            <S.RelativeElement>
              <S.InformationArea>
                {showStrikethroughPrices ? (
                  <>
                    <S.OneLineText
                      variant="subtitle"
                      component="p"
                      alignment="center"
                      testId="product-tile-title"
                      data-cs-capture=""
                    >
                      {singleProduct.name}
                    </S.OneLineText>

                    <TilePriceStrikethrough singleProduct={singleProduct} />
                  </>
                ) : (
                  <TileTitleWithPrice
                    renderLowestPrice={renderLowestPrice}
                    product={singleProduct}
                  />
                )}
              </S.InformationArea>

              {hasVariantArea && (
                <VariantArea
                  wrapperRef={wrapperRef}
                  product={product}
                  variants={variants}
                  onVariantLinkClick={onVariantLinkClick}
                  setVariantHovered={setVariantHovered}
                />
              )}
            </S.RelativeElement>

            {!showStrikethroughPrices && (
              <TilePriceLowest30Days
                fromPrice={fromPrice}
                renderLowestPrice={renderLowestPrice}
                productPrice={productPrice}
                product={singleProduct}
              />
            )}
          </S.ProductData>
        </S.ProductWrapper>
      </S.TileLink>
    </S.Tile>
  );
};
