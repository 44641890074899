import { useCallback, useEffect, useState } from 'react';
import type { ReactElement, ChangeEvent } from 'react';
import type { SubmitHandler, FieldValues, UseFormRegister } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import mailcheck from 'mailcheck';
import { colors, media } from '../../../../shared/core/styles';
import { Typography } from '../../../../shared/core/typography/Typography';
import { Button } from '../../../../shared/core/button/Button';
import { Dialog } from '../../../../shared/core/dialog/Dialog';
import type { Option } from '../../../../shared/core/form';
import { InputField, Checkbox, Select } from '../../../../shared/core/form';
import type { ProductOverlay as ProductOverlaySchema } from '../../../../../amplienceTypes/schemas/imported/product-overlay-schema';
import type { Product } from '../../../../../utilities/graphql/codegen';
import {
  EntryPoint,
  SizeType,
  SubscribeStatusCode,
} from '../../../../../utilities/graphql/codegen';
import { useAppContext } from '../../../../../utilities/context/static/AppContext';
import { useSizeContext } from '../../../../../utilities/context/dynamic/SizeContext';
import { useUserContext } from '../../../../../utilities/context/dynamic/UserContext';
import {
  EMAIL_VARIABLE,
  PRIVACY_POLICY_VARIABLE,
} from '../../../../../utilities/constants/amplienceVariables';
import { useDataLayerContext } from '../../../../../utilities/context/static/DataLayerContext';
import { QubitCustomEvents } from '../../../../../utilities/context/dynamic/ExperienceOptionsProvider';
import { useStaticContext } from '../../../../../utilities/context/static/StaticContext';
import { getFormSchema } from '../../../../../utilities/constants/formSchema';
import { subscribeToGstar } from '../../../../../utilities/helpers';

interface EmailSuggestion {
  address: string;
  domain: string;
  full: string;
}

export interface ProductOverlayDialogProps {
  onClose: () => void;
  waistLabel: string;
  lengthLabel: string;
  sizeLabel: string;
  product: Product;
}

const S = {
  Section: styled.section`
    padding: 0 20px;

    @media ${media.greaterThan('sm')} {
      padding: 0 75px 0 50px;
    }
  `,

  Title: styled(Typography)`
    font-weight: 400;
    line-height: 1em;
    padding: 50px 0 15px;
    text-transform: none;

    @media ${media.greaterThan('sm')} {
      font-size: 32px;
    }
  `,

  Description: styled(Typography)`
    margin-bottom: 35px;
  `,

  Error: styled(Typography)`
    line-height: 1.4;
    padding-top: 5px;
    margin-bottom: 13px;
    font-weight: normal;
  `,

  FormGroup: styled.div`
    margin-bottom: 14px;
  `,

  Sizes: styled.div`
    display: flex;
    gap: 10px;
    align-items: flex-end;
    width: 100%;

    @media ${media.greaterThan('sm')} {
      width: 74%;
    }
  `,

  SizesWrapper: styled.div`
    margin-bottom: 14px;
    display: flex;
    align-items: center;
  `,

  SizeLabel: styled.span`
    display: none;

    @media ${media.greaterThan('sm')} {
      display: block;
      font-size: 13px;
      width: 26%;
    }
  `,

  Select: styled(Select)`
    label {
      line-height: 1.3;
      font-weight: normal;

      @media ${media.greaterThan('sm')} {
        display: none;
      }
    }
  `,

  InputField: styled(InputField)`
    label {
      padding: 5px 0;
      margin: 0;
      line-height: 1.3;
      font-weight: normal;

      @media ${media.greaterThan('sm')} {
        padding-top: 5px;
        width: 26%;
      }
    }

    div {
      input {
        height: auto;
        padding: 11px 10px;
      }

      @media ${media.greaterThan('sm')} {
        width: 74%;
      }
    }

    @media ${media.greaterThan('sm')} {
      flex-direction: row;
      align-items: center;
    }
  `,

  Subscription: styled.div`
    margin-bottom: 14px;

    @media ${media.greaterThan('sm')} {
      margin-left: 26%;
      width: 74%;
    }
  `,

  Link: styled.a`
    text-decoration: none;
    text-decoration: underline;
    color: ${colors.NERO_GREY};
  `,

  Checkbox: styled(Checkbox)`
    padding: 5px 10px 5px 36px;

    span {
      line-height: 1.3;
      padding: 0;
    }

    div:last-child {
      top: 5px;
    }
  `,

  Submit: styled.div`
    padding-top: 35px;
    margin-bottom: 50px;

    @media ${media.greaterThan('sm')} {
      padding: 0;
      text-align: right;
    }
  `,

  SubmitBtn: styled(Button)`
    font-size: 13px;
    width: 100%;

    @media ${media.greaterThan('sm')} {
      width: auto;
    }
  `,

  SuccessSection: styled.section`
    padding: 38px 10%;
  `,

  SuccessContent: styled.div`
    margin-bottom: 35px;
  `,

  SuccessTitle: styled(Typography)`
    padding: 50px 0 15px;
    text-transform: none;
    font-weight: 400;
    line-height: 1em;
  `,

  SuccessEmail: styled(Typography)`
    font-weight: 700;
  `,

  SuccessConfirmation: styled(Typography)`
    margin-top: 15px;
  `,

  Continue: styled.div`
    width: 100%;
    text-align: center;
  `,
};

interface ProductOverlayDialogType extends FieldValues {
  gridValue1: string;
  gridValue2: string;
  name: string;
  email: string;
  subscribed: boolean;
}

export const ProductOverlayDialog = ({
  waistLabel,
  lengthLabel,
  onClose,
  backInStockTitle,
  backInStockSubtitle,
  comingSoonTitle,
  comingSoonSubtitle,
  nameLabel,
  emailLabel,
  namePlaceholder,
  emailPlaceholder,
  sizeLabel,
  subscriptionLabel,
  privacyPolicy,
  submitLabel,
  duplicateErrorLabel,
  genericErrorLabel,
  successTitle,
  successSubtitle,
  successConfirmation,
  successBtn,
  loadingLabel,
  suggestionLabel,
  product,
}: ProductOverlayDialogProps & ProductOverlaySchema): ReactElement => {
  const { comingSoon, sizeInformation, code, baseProduct } = product;

  const [success, setSuccess] = useState(false);
  const [isAlreadySubscribed, setIsAlreadySubscribed] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [emailSuggestion, setEmailSuggestion] = useState('');
  const [subsValues, setSubsValues] = useState<ProductOverlayDialogType>({
    email: '',
    gridValue1: '',
    gridValue2: '',
    name: '',
    subscribed: false,
  });
  const [gridValueOneSizes, setGridValueOneSizes] = useState<
    | {
        value: string | undefined | null;
        label: string | undefined | null;
      }[]
    | undefined
  >([]);
  const [gridValueTwoSizes, setGridValueTwoSizes] = useState<
    | {
        value: string | undefined | null;
        label: string | undefined | null;
      }[]
    | undefined
  >([]);
  const { pushToContentSquare } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const { formErrorLabels } = useStaticContext();
  const { gridValues } = useSizeContext();
  const { user } = useUserContext();

  const filterGridValueOneSizes = useCallback(
    (gridValue2?: string) =>
      sizeInformation?.sizes
        ?.filter(size => gridValue2 === undefined || size?.gridValue2?.startsWith(gridValue2))
        .filter(
          (size, index, self) =>
            index === self.findIndex(item => item?.gridValue1 === size?.gridValue1)
        )
        .map(size => ({
          value: size?.gridValue1,
          label: size?.gridValue1,
        })),
    [sizeInformation?.sizes]
  );

  const filterGridValueTwoSizes = useCallback(
    (gridValue1: string) =>
      sizeInformation?.sizes
        ?.filter(size => size?.gridValue1?.startsWith(gridValue1))
        .filter(
          (size, index, self) =>
            index === self.findIndex(item => item?.gridValue2 === size?.gridValue2)
        )
        .map(size => ({
          value: size?.gridValue2,
          label: size?.gridValue2,
        })),
    [sizeInformation?.sizes]
  );

  useEffect(() => {
    if (sizeInformation?.sizeType !== SizeType.NumericDouble) {
      setGridValueOneSizes(filterGridValueOneSizes());
    } else {
      setGridValueOneSizes(filterGridValueOneSizes(gridValues.gridValue2));
      setGridValueTwoSizes(filterGridValueTwoSizes(gridValues.gridValue1 || ''));
    }
  }, [filterGridValueOneSizes, filterGridValueTwoSizes, gridValues, sizeInformation?.sizeType]);

  const { t } = useTranslation(['common', 'pdp']);

  const ProductOverlayDialogSchema = getFormSchema({
    gridValue1RequiredLabel:
      formErrorLabels?.gridValue1RequiredLabel ||
      t('globalFormErrorLabels.gridValue1RequiredLabel', { ns: 'common' }),
    gridValue2RequiredLabel:
      formErrorLabels?.gridValue2RequiredLabel ||
      t('globalFormErrorLabels.gridValue2RequiredLabel', { ns: 'common' }),
    nameRequiredLabel:
      formErrorLabels?.nameRequiredLabel ||
      t('globalFormErrorLabels.nameRequiredLabel', { ns: 'common' }),
    emailRequiredLabel:
      formErrorLabels?.emailRequiredLabel ||
      t('globalFormErrorLabels.emailRequiredLabel', { ns: 'common' }),
    emailInvalidLabel:
      formErrorLabels?.emailInvalidLabel ||
      t('globalFormErrorLabels.emailInvalidLabel', { ns: 'common' }),
  });

  useEffect(() => {
    pushToDataLayer({
      events: {
        category: `${comingSoon ? 'coming soon' : 'stock'} notification`,
        action: 'open',
        label: undefined,
      },
      product: {
        materialNumber: code,
      },
      event: `${comingSoon ? 'comingsoon' : 'stock'}notification-open`,
    });
  }, [code, pushToDataLayer, comingSoon]);

  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent(QubitCustomEvents.TK22_1_SEND_GA_EVENT, {
        detail: { label: 'view_notifyme' },
      })
    );
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ProductOverlayDialogType>({ mode: 'onSubmit' });

  const subscribeProduct: SubmitHandler<ProductOverlayDialogType> = async values => {
    setSubsValues(values);

    const entryPoint = comingSoon ? EntryPoint.ProductComingSoon : EntryPoint.VariantBackInStock;

    try {
      const response = await subscribeToGstar({
        isNewsLetterSubscription: values.subscribed,
        entryPoint,
        email: values.email,
        name: values.name,
        gridValue1: product.oneSize ? 'PC' : values.gridValue1,
        gridValue2: values.gridValue2,
        productCode: baseProduct || code,
      });

      if (response?.subscribeStatusCode === SubscribeStatusCode.Success) {
        setIsAlreadySubscribed(false);
        setSubscriptionError(false);
        setSuccess(true);

        pushToDataLayer({
          events: {
            category: `${comingSoon ? 'coming soon' : 'stock'} notification`,
            action: 'submit',
            label: undefined,
          },
          product: {
            materialNumber: code,
          },
          event: `${comingSoon ? 'comingsoon' : 'stock'}notification-submit`,
        });

        pushToContentSquare(
          'trackPageEvent',
          `Action | Subscribed to ${comingSoon ? 'coming soon' : 'notify me when back in stock'}`
        );

        if (values.subscribed) {
          document.dispatchEvent(
            new CustomEvent(QubitCustomEvents.TK22_1_SEND_GA_EVENT, {
              detail: { label: 'click_newsletteroptin_successful' },
            })
          );
        }

        setTimeout(() => {
          setSuccess(false);
          onClose();
          setSubsValues({
            email: '',
            gridValue1: '',
            gridValue2: '',
            name: '',
            subscribed: false,
          });
        }, 5000);
      } else if (response?.subscribeStatusCode === SubscribeStatusCode.Failure) {
        setIsAlreadySubscribed(true);
        setSuccess(false);
      } else {
        // Other errors from subscription
        setSubscriptionError(true);
        setSuccess(false);
      }
    } catch (error) {
      setSubscriptionError(true);
      setSuccess(false);
    }
  };

  const suggest = (email: string) => {
    mailcheck.run({
      email,
      suggested: (suggestion: EmailSuggestion) => {
        setEmailSuggestion(suggestion.full);
        setShowSuggestion(true);
      },
      empty: () => {
        setEmailSuggestion('');
        setShowSuggestion(false);
      },
    });
  };

  const subscriptionEl = (
    <Typography variant="body" component="p" color={colors.NERO_GREY}>
      {
        (
          subscriptionLabel ||
          t('productSizeSelector.productOverlayDialog.subscriptionLabel', { ns: 'common' })
        )?.split(PRIVACY_POLICY_VARIABLE)[0]
      }
      <span>
        <S.Link href="/help-info/privacy-policy">
          {privacyPolicy ||
            t('productSizeSelector.productOverlayDialog.privacyPolicy', { ns: 'common' })}
        </S.Link>
      </span>
      {
        (
          subscriptionLabel ||
          t('productSizeSelector.productOverlayDialog.subscriptionLabel', { ns: 'common' })
        )?.split(PRIVACY_POLICY_VARIABLE)[1]
      }
    </Typography>
  );

  return (
    <Dialog
      onClose={onClose}
      overlayColor={colors.DIALOG_GREY}
      ariaLabel="product-overlay-dialog"
      testId="product-subscription-overlay"
    >
      {success ? (
        <S.SuccessSection>
          <S.SuccessContent data-testid="product-subscription-success-overlay">
            <S.SuccessTitle variant="h1" component="h1">
              {successTitle ||
                t('productSizeSelector.productOverlayDialog.successTitle', { ns: 'common' })}
            </S.SuccessTitle>
            <Typography variant="body" component="p" testId="product-subscription-success-message">
              {
                (
                  successSubtitle ||
                  t('productSizeSelector.productOverlayDialog.successSubtitle', { ns: 'common' })
                )?.split(EMAIL_VARIABLE)[0]
              }
              <S.SuccessEmail variant="body" component="span">
                {subsValues.email}
              </S.SuccessEmail>
              {
                (
                  successSubtitle ||
                  t('productSizeSelector.productOverlayDialog.successSubtitle', { ns: 'common' })
                )?.split(EMAIL_VARIABLE)[1]
              }
            </Typography>
            {subsValues.subscribed && (
              <S.SuccessConfirmation variant="body" component="p">
                {successConfirmation ||
                  t('productSizeSelector.productOverlayDialog.successConfirmation', {
                    ns: 'common',
                  })}
              </S.SuccessConfirmation>
            )}
          </S.SuccessContent>
          <S.Continue>
            <Button
              label={
                successBtn ||
                t('productSizeSelector.productOverlayDialog.successBtn', { ns: 'common' })
              }
              ordinal="success"
              onClick={onClose}
              testId="product-subscription-success-continue"
            />
          </S.Continue>
        </S.SuccessSection>
      ) : (
        <S.Section>
          <S.Title variant="h1" component="h1">
            {comingSoon
              ? comingSoonTitle ||
                t('productSizeSelector.productOverlayDialog.comingSoonTitle', { ns: 'common' })
              : backInStockTitle ||
                t('productSizeSelector.productOverlayDialog.backInStockTitle', { ns: 'common' })}
          </S.Title>
          <S.Description variant="body" component="p">
            {comingSoon
              ? comingSoonSubtitle ||
                t('productSizeSelector.productOverlayDialog.comingSoonSubtitle', { ns: 'common' })
              : backInStockSubtitle ||
                t('productSizeSelector.productOverlayDialog.backInStockSubtitle', { ns: 'common' })}
          </S.Description>
          {(isAlreadySubscribed || subscriptionError) && (
            <S.Error
              variant="h5"
              component="p"
              color={colors.ERROR_RED}
              testId="product-subscription-error"
            >
              {isAlreadySubscribed
                ? duplicateErrorLabel ||
                  t('productSizeSelector.productOverlayDialog.duplicateErrorLabel', {
                    ns: 'common',
                  })
                : genericErrorLabel ||
                  t('productSizeSelector.productOverlayDialog.genericErrorLabel', { ns: 'common' })}
            </S.Error>
          )}
          <form onSubmit={handleSubmit(subscribeProduct)}>
            {product.oneSize || comingSoon ? (
              <></>
            ) : (
              <S.SizesWrapper>
                <S.SizeLabel>{sizeLabel}</S.SizeLabel>
                <S.Sizes>
                  {sizeInformation?.sizeType !== SizeType.NumericDouble ? (
                    <Controller
                      control={control}
                      name="gridValue1"
                      defaultValue={gridValues.gridValue1}
                      render={({ field: { onChange, value } }) => (
                        <S.Select
                          name="gridValue1"
                          options={gridValueOneSizes as Option[]}
                          register={register as unknown as UseFormRegister<FieldValues>}
                          ordinal="secondary"
                          label={sizeLabel}
                          defaultValue={gridValues.gridValue1}
                          value={value}
                          onChange={onChange}
                          overflowOption
                          placeholder={sizeLabel}
                          required={ProductOverlayDialogSchema.gridValue1.required}
                          error={errors.gridValue1 && errors.gridValue1.message}
                          testId="product-overlay-width"
                        />
                      )}
                    />
                  ) : (
                    <>
                      <Controller
                        control={control}
                        name="gridValue1"
                        defaultValue={gridValues.gridValue1}
                        render={({ field: { onChange, value } }) => (
                          <S.Select
                            name="gridValue1"
                            options={gridValueOneSizes as Option[]}
                            register={register as unknown as UseFormRegister<FieldValues>}
                            ordinal="secondary"
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                              onChange(e);

                              const newGridValueTwoSizes = filterGridValueTwoSizes(e.target.value);
                              const isExist = newGridValueTwoSizes?.some(
                                size => size.value === getValues('gridValue2')
                              );

                              if (!isExist) {
                                setValue('gridValue2', '');
                              }

                              setGridValueTwoSizes(newGridValueTwoSizes);
                            }}
                            overflowOption
                            placeholder={waistLabel}
                            value={value}
                            required={ProductOverlayDialogSchema.gridValue1.required}
                            error={errors.gridValue1 && errors.gridValue1.message}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="gridValue2"
                        defaultValue={gridValues.gridValue2}
                        render={({ field: { onChange, value } }) => (
                          <S.Select
                            name="gridValue2"
                            options={gridValueTwoSizes as Option[]}
                            register={register as unknown as UseFormRegister<FieldValues>}
                            ordinal="secondary"
                            overflowOption
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                              onChange(e);

                              const newGridValueOneSizes = filterGridValueOneSizes(e.target.value);
                              const isExist = newGridValueOneSizes?.some(
                                size => size.value === getValues('gridValue1')
                              );

                              if (!isExist) {
                                setValue('gridValue1', '');
                              }

                              setGridValueOneSizes(newGridValueOneSizes);
                            }}
                            placeholder={lengthLabel}
                            value={value}
                            required={ProductOverlayDialogSchema.gridValue2.required}
                            error={errors.gridValue2 && errors.gridValue2.message}
                          />
                        )}
                      />
                    </>
                  )}
                </S.Sizes>
              </S.SizesWrapper>
            )}
            <S.FormGroup>
              <Controller
                control={control}
                name="name"
                defaultValue={`${user?.firstName || ''}${
                  user?.lastName ? ` ${user.lastName}` : ''
                }`}
                render={({ field: { onChange, value } }) => (
                  <S.InputField
                    label={
                      nameLabel ||
                      t('productSizeSelector.productOverlayDialog.nameLabel', { ns: 'common' })
                    }
                    placeholder={
                      namePlaceholder ||
                      t('productSizeSelector.productOverlayDialog.namePlaceholder', {
                        ns: 'common',
                      })
                    }
                    name="name"
                    required={ProductOverlayDialogSchema.name.required}
                    register={register as unknown as UseFormRegister<FieldValues>}
                    onChange={onChange}
                    value={value}
                    error={errors.name && errors.name.message}
                    testId="product-subscription-name"
                  />
                )}
              />
            </S.FormGroup>
            <S.FormGroup>
              <Controller
                control={control}
                name="email"
                defaultValue={user?.email || ''}
                render={({ field: { onChange, value } }) => (
                  <S.InputField
                    label={
                      emailLabel ||
                      t('productSizeSelector.productOverlayDialog.emailLabel', { ns: 'common' })
                    }
                    placeholder={
                      emailPlaceholder ||
                      t('productSizeSelector.productOverlayDialog.emailPlaceholder', {
                        ns: 'common',
                      })
                    }
                    name="email"
                    required={ProductOverlayDialogSchema.email.required}
                    pattern={ProductOverlayDialogSchema.email.pattern}
                    register={register as unknown as UseFormRegister<FieldValues>}
                    setValue={setValue}
                    error={errors.email && errors.email.message}
                    showSuggestion={showSuggestion}
                    setShowSuggestion={(suggested: boolean) => setShowSuggestion(suggested)}
                    suggestionText={
                      suggestionLabel ||
                      t('productSizeSelector.productOverlayDialog.suggestionLabel', {
                        ns: 'common',
                      })
                    }
                    suggestionValue={emailSuggestion}
                    separate
                    onBlur={(e: ChangeEvent<HTMLInputElement>) => suggest(e.target.value)}
                    testId="product-subscription-email"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </S.FormGroup>
            <S.Subscription>
              <S.Checkbox
                label={subscriptionEl}
                name="subscribed"
                register={register as unknown as UseFormRegister<FieldValues>}
                testId="product-subscription-privacy-policy"
              />
            </S.Subscription>
            <S.Submit>
              <S.SubmitBtn
                type="submit"
                label={
                  isSubmitting
                    ? loadingLabel ||
                      t('productSizeSelector.productOverlayDialog.loadingLabel', { ns: 'common' })
                    : submitLabel ||
                      t('productSizeSelector.productOverlayDialog.submitLabel', { ns: 'common' })
                }
                disabled={isSubmitting}
                ordinal="primary"
                testId="product-subscription-submit"
                onClick={() =>
                  document.dispatchEvent(
                    new CustomEvent(QubitCustomEvents.TK22_1_SEND_GA_EVENT, {
                      detail: { label: 'click_submit' },
                    })
                  )
                }
              />
            </S.Submit>
          </form>
        </S.Section>
      )}
    </Dialog>
  );
};
