import styled from 'styled-components';
import { colors, media } from '../../../../shared/core/styles';

export const S = {
  ProductPriceWrapper: styled.div<{ $lowestPriceDisplay: string }>`
    min-height: 48px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    max-width: 100%;
    position: relative;
    color: ${colors.NERO_GREY};
    margin: 8px 0;

    @media ${media.greaterThan('lg')} {
      /* overflow: hidden; RRP Tooltip require overflow visible */
      padding: 0;
      text-align: left;
    }

    @media ${media.between('xs', 'lg')} {
      padding: 0;
      text-align: ${({ $lowestPriceDisplay }) =>
        $lowestPriceDisplay === 'NEVER' ? 'center' : 'left'};
    }
  `,
  PriceDiscount: styled.span`
    color: ${colors.BLUE_CHARCOAL};
    font-weight: 325;
    text-decoration: line-through;

    @media ${media.greaterThan('xs')} {
      margin-right: 5px;
    }

    @media ${media.greaterThan('sm')} {
      margin-right: 7px;
    }
  `,
  PriceValue: styled.strong<{ $basePriceColor?: string; $hasDiscountPrice: boolean }>`
    ${({ $basePriceColor, $hasDiscountPrice }) =>
      $hasDiscountPrice
        ? `color: ${$basePriceColor || colors.LIGHT_GREEN};`
        : `color: ${colors.NERO_GREY};`}

    @media ${media.greaterThan('xs')} {
      margin: 0 5px;
    }

    @media ${media.greaterThan('sm')} {
      margin: 0 7px;
    }
  `,
  PriceTax: styled.span`
    color: ${colors.BALI_HAI_GREY};
    font-size: 14px;
    font-style: normal;
    font-weight: 325;
    white-space: nowrap;
    vertical-align: bottom;

    @media ${media.greaterThan('lg')} {
      color: ${colors.FIORD_GREY};
    }
  `,

  PriceLowest30Days: styled.span`
    font-size: 12px;
    font-weight: 325;
    line-height: 16px;
    color: ${colors.RAVEN_GREY};
  `,

  PriceInfo: styled.div<{ $hasRrpTooltip?: boolean }>`
    ${({ $hasRrpTooltip }) => {
      if (!$hasRrpTooltip) {
        return `
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        `;
      }

      return '';
    }}
    display:flex;
    align-items: center;
    position: relative;
    color: ${colors.RAVEN_GREY};
    font-size: 16px;
    height: 24px;
    font-style: normal;
    line-height: 16px;
    margin: 4px 0;

    @media ${media.greaterThan('lg')} {
      justify-content: start;
    }
  `,

  PriceOriginal: styled.span`
    text-decoration: line-through;
  `,

  TooltipContainer: styled.span`
    position: relative;
    display: inline-block;
  `,
  TooltipIcon: styled.i<{ $active?: boolean }>`
    color: ${({ $active }) => ($active ? colors.BLACK : colors.RAVEN_GREY)};
    font-size: 12px;
    line-height: 16px;
    transition: color 0.25s;

    &::before {
      font-family: gstar-icons;
      font-style: normal;
      content: '\\F155';
      margin: 0 0.2em 0 0;
    }

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &:focus {
      color: ${colors.BLACK};
    }
  `,
  IconArrow: styled.i`
    position: relative;
    display: block;
    top: 0;
    left: 2px;
    z-index: 55;
    transform: rotate(180deg);

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      border: 10px solid transparent;
    }

    &::before {
      top: -10px;
      border-top-color: ${colors.DISABLED_GREY};
    }

    &::after {
      top: -12px;
      border-top-color: ${colors.WHITE};
    }
  `,
};
