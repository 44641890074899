import type { ComponentType, ElementType } from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import {
  FIBER_RANKING_LANGUAGE,
  FIBER_RANKING_SCORE_THRESHOLD,
  fiberRankingAccessibilityTitles,
} from '../../../../utilities/constants/fiberRanking';

const S = {
  TileFiberRanking: styled.i<{ $language: string }>`
    display: block;
    width: 50px;
    height: 50px;
    background: ${({ $language }) =>
      `url('/_fes/${process.env.BUILD_TIME}/img/svg/fiber-ranking-badge-${$language}.svg')`};
  `,
};

export const TileFiberRanking = ({
  fiberRankingScore = 0,
  wrapper,
}: {
  fiberRankingScore: number | undefined | null;
  wrapper: ComponentType;
}) => {
  const {
    configuration: { fiberRanking, displayFiberRankingOnProductTiles },
  } = useStaticContext();
  const { locale } = useAppContext();
  const [language] = locale.split('_');

  const Wrapper: ElementType = wrapper ?? <></>;
  const fiberRankingLang = FIBER_RANKING_LANGUAGE.has(language) ? language : 'en';
  const fiberRankingAccessibilityTitle = fiberRankingAccessibilityTitles[fiberRankingLang] || '';

  const showFiberRanking =
    displayFiberRankingOnProductTiles &&
    fiberRanking &&
    fiberRankingScore &&
    FIBER_RANKING_SCORE_THRESHOLD <= fiberRankingScore;

  if (!showFiberRanking) {
    return <></>;
  }

  return (
    <Wrapper>
      <S.TileFiberRanking $language={fiberRankingLang} title={fiberRankingAccessibilityTitle} />
    </Wrapper>
  );
};
