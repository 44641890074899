import type { MouseEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';
import Image from 'next/legacy/image';
import type { SimplifiedImageEntry, StyleVariant } from '../../../../utilities/graphql/codegen';
import { colors, media } from '../../core/styles';
import type { AnimationDirection } from '../../../pdp/productDetail/productImageGallery/ProductImageGallery';
import { imageBlurData } from '../../../../utilities/constants/base64Images';
import {
  createProfileRegExp,
  preferredImageProfiles,
  productImagePropertyInitValue,
} from '../../../pdp/productDetail/productImageGallery/ProductImage';
import type { ProductImageProperty } from '../../../pdp/productDetail/productImageGallery/ProductImage';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';

const styles = {
  ArrowButton: css`
    cursor: pointer;
    display: block;
    position: absolute;
    width: 44px;
    height: 64px;
    top: 50%;
    margin-top: -32px;
    text-align: center;
    z-index: 13;

    &::before {
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      line-height: 64px;
      text-decoration: inherit;
      width: 1em;
      text-align: center;
      vertical-align: middle;
      font-variant: normal;
      text-transform: none;
      font-size: 40px;
      color: ${colors.ACCESSIBILITY_GREY};
    }
  `,
};

const S = {
  ProductImagePanel: styled.div`
    width: 100%;
    height: 100%;
    position: relative;
  `,
  Carousel: styled.div`
    overflow: hidden;
    position: relative;
    height: 100%;
  `,
  CarouselInner: styled.div`
    width: 100%;
    height: 100%;
    position: relative;
  `,
  ArrowLeft: styled.button`
    ${styles.ArrowButton};
    border: 0;
    left: 0;

    &::before {
      content: '\\2039';
    }
  `,
  ArrowRight: styled.button`
    ${styles.ArrowButton};
    border: 0;
    right: 0;

    &::before {
      content: '\\203A';
    }
  `,
  CarouselNavigation: styled.div<{ $currentImageIndex: number }>`
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);

    button {
      display: inline-block;
      cursor: pointer;
      width: 12px;
      height: 12px;
      position: relative;
      border: none;

      &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        margin-top: -3px;
        margin-left: -3px;
        background: ${colors.LIGHT_GREY};
        content: '';
        display: block;
        height: 6px;
        width: 6px;
      }
    }

    button:nth-child(${({ $currentImageIndex }) => $currentImageIndex + 1}) {
      &::before {
        background: ${colors.ACCESSIBILITY_GREY};
      }
    }
  `,
  CarouselInnerItem: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    transform: translateX(100%);

    &:first-child {
      transform: translateX(0);
    }
  `,
  ImageWrapper: styled.div`
    position: inherit;
    width: 100%;
    height: 100%;

    & span {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }

    & img {
      position: unset !important;
    }
  `,

  Image: styled(Image)`
    max-width: unset !important;
    min-width: unset !important;
    width: auto !important;

    @media ${media.between('sm', 'lg')} {
      transform: translateX(11%);
    }
  `,

  ZoomPanel: styled.div<{ $zoomIn: boolean }>`
    position: absolute;
    width: auto;
    height: auto;
    inset: 0;
    cursor: url('/_fes/${process.env.BUILD_TIME}/img/zoom/zoom-out-cursor.cur') 18 18, default;
    z-index: ${({ $zoomIn }) => ($zoomIn ? 1 : -1)};
    opacity: ${({ $zoomIn }) => ($zoomIn ? 1 : 0)};
    overflow: hidden;
    transition: 600ms;

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  ZoomImage: styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: max(100%, 2000px);
    height: auto;
  `,
};

interface QuickViewProductImageProps {
  simplifiedImages?: SimplifiedImageEntry | null;
  productStyleVariantInfo?: StyleVariant | null;
  shouldUseDarkBackgroundImgs: boolean;
}

export const QuickViewProductImage = ({
  simplifiedImages,
  productStyleVariantInfo,
  shouldUseDarkBackgroundImgs,
}: QuickViewProductImageProps) => {
  const { accessibilityLabels } = useStaticContext();
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [productImageList, setProductImageList] = useState<ProductImageProperty[]>([]);
  const [zoomIn, setZoomIn] = useState(false);

  const galleryRef = useRef<HTMLDivElement>(null);
  const gsapQuery = gsap.utils.selector(galleryRef);

  const zoomPanelRef = useRef<HTMLDivElement>(null);
  const zoomImageWrapperRef = useRef<HTMLDivElement>(null);

  const initProductImageList = useCallback(() => {
    const profileRegExp = createProfileRegExp(!!shouldUseDarkBackgroundImgs);

    if (simplifiedImages?.entry) {
      const imageListObj: ProductImageProperty[] = [];

      simplifiedImages.entry.forEach(obj => {
        if (!!obj?.key && obj?.value && obj?.value?.url) {
          const profileName = obj.key;

          if (
            profileName.match(profileRegExp) &&
            preferredImageProfiles.filter(item => !/W$/.test(item)).includes(profileName)
          ) {
            imageListObj.push(JSON.parse(JSON.stringify(productImagePropertyInitValue)));

            const lastIndex = imageListObj.length - 1;

            imageListObj[lastIndex].profile = profileName;
            imageListObj[lastIndex].url = obj.value.url;
            imageListObj[lastIndex].altText = obj.value.altText || '';
          }
        }
      });

      imageListObj.sort((a, b) => (a.profile && b.profile && a.profile < b.profile ? -1 : 1));

      setProductImageList(imageListObj);
    }
  }, [simplifiedImages?.entry, shouldUseDarkBackgroundImgs]);

  useEffect(() => {
    initProductImageList();
  }, [initProductImageList]);

  const slideToNextImage = useCallback(
    (nextIndex: number) => {
      if (currentImageIndex === nextIndex) {
        return;
      }

      let direction: AnimationDirection = 'left';

      if (nextIndex < currentImageIndex) {
        if (
          currentImageIndex - nextIndex <=
          nextIndex + productImageList.length - currentImageIndex
        ) {
          direction = 'right';
        }
      } else if (
        currentImageIndex + productImageList.length - nextIndex <
        nextIndex - currentImageIndex
      ) {
        direction = 'right';
      }

      gsap.to(gsapQuery('.gallery-item')[nextIndex], {
        x: direction === 'left' ? '100%' : '-100%',
        duration: 0,
      });
      gsap.to(gsapQuery('.gallery-item')[currentImageIndex], {
        x: direction === 'left' ? '-100%' : '100%',
        duration: 0.6,
      });
      gsap.to(gsapQuery('.gallery-item')[nextIndex], {
        x: '0',
        duration: 0.6,
      });

      setCurrentImageIndex(nextIndex);
    },
    [currentImageIndex, gsapQuery, productImageList.length]
  );

  const setZoomPanelScrollXCenter = (e: MouseEvent) => {
    if (setZoomIn) {
      setZoomIn(true);
    }

    if (zoomPanelRef.current && zoomImageWrapperRef.current) {
      if (zoomImageWrapperRef.current) {
        zoomPanelRef.current.scrollLeft = 0;
      }

      const panelBounds = e.currentTarget.getBoundingClientRect();
      const mouseX = e.clientX - panelBounds.left;
      const mouseY = e.clientY - panelBounds.top;

      zoomImageWrapperRef.current.style.left = `-${
        ((2000 - panelBounds.width) / panelBounds.width) * mouseX
      }px`;
      zoomImageWrapperRef.current.style.top = `-${
        ((2000 - panelBounds.height) / panelBounds.height) * mouseY
      }px`;
    }
  };

  const onMouseMoveOnZoomPanel = (event: MouseEvent) => {
    const panelBounds = event.currentTarget?.getBoundingClientRect();

    if (zoomImageWrapperRef.current) {
      const mouseX = event.clientX - panelBounds.left;
      const mouseY = event.clientY - panelBounds.top;

      zoomImageWrapperRef.current.style.left = `-${
        ((2000 - panelBounds.width) / panelBounds.width) * mouseX
      }px`;
      zoomImageWrapperRef.current.style.top = `-${
        ((2000 - panelBounds.height) / panelBounds.height) * mouseY
      }px`;
    }
  };

  return (
    <S.ProductImagePanel>
      <S.Carousel>
        <S.CarouselInner ref={galleryRef} data-testid="quick-view-image">
          {productImageList.map((productImage, index) =>
            productStyleVariantInfo ? (
              <S.CarouselInnerItem key={index} className="gallery-item">
                <S.ImageWrapper>
                  <S.Image
                    layout="fill"
                    objectFit="contain"
                    alt={productStyleVariantInfo.images?.thumbnailAltText || ''}
                    src={
                      productStyleVariantInfo.images?.primaryImage?.replace(
                        /h_(\d+)/,
                        'h_630,w_630'
                      ) || ''
                    }
                    quality="100"
                  />
                </S.ImageWrapper>
              </S.CarouselInnerItem>
            ) : (
              <S.CarouselInnerItem key={index} className="gallery-item">
                <S.ImageWrapper>
                  <S.Image
                    layout="fill"
                    objectFit="contain"
                    alt={productImage.altText}
                    src={productImage.url.replace('{{dimensions}}', 'h_630') || ''}
                    onClick={setZoomPanelScrollXCenter}
                    priority={index === 0}
                    quality="100"
                    aria-label={accessibilityLabels?.quickView?.showZoomPanel}
                  />
                </S.ImageWrapper>
              </S.CarouselInnerItem>
            )
          )}
        </S.CarouselInner>
        <S.ArrowLeft
          onClick={() => {
            const nextIndex =
              currentImageIndex === 0 ? productImageList.length - 1 : currentImageIndex - 1;

            slideToNextImage(nextIndex);
          }}
          aria-label={accessibilityLabels?.quickView?.arrowLeft}
        />
        <S.ArrowRight
          onClick={() => {
            const nextIndex =
              currentImageIndex === productImageList.length - 1 ? 0 : currentImageIndex + 1;

            slideToNextImage(nextIndex);
          }}
          aria-label={accessibilityLabels?.quickView?.arrowRight}
        />
        <S.CarouselNavigation $currentImageIndex={currentImageIndex}>
          {productImageList.map((productImage, index) => (
            <button
              aria-label={`${accessibilityLabels?.quickView?.slideTo}${index + 1}`}
              key={index}
              onClick={() => slideToNextImage(index)}
              type="button"
            />
          ))}
        </S.CarouselNavigation>
      </S.Carousel>
      <S.ZoomPanel
        ref={zoomPanelRef}
        $zoomIn={zoomIn}
        onMouseMove={onMouseMoveOnZoomPanel}
        onClick={() => setZoomIn(false)}
        aria-label={accessibilityLabels?.quickView?.closeZoomPanel}
      >
        <S.ZoomImage ref={zoomImageWrapperRef}>
          <Image
            data-testid="image-zoom-image"
            src={
              productImageList[currentImageIndex]?.url?.replace('{{dimensions}}', 'h_2000') || ''
            }
            alt={productImageList[currentImageIndex]?.altText || ''}
            blurDataURL={imageBlurData}
            placeholder="blur"
            width={2000}
            height={2000}
          />
        </S.ZoomImage>
      </S.ZoomPanel>
    </S.ProductImagePanel>
  );
};
