import type { ReactElement } from 'react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import type { HotspotsImage } from '../../../amplienceTypes/content';
import { linkTargetRegex } from '../../../utilities/constants/regex';
import { animations, colors, media } from '../../shared/core/styles';
import { NextLink } from '../../shared/core/link/NextLink';
import type { Product, ProductsProduct } from '../../../utilities/graphql/codegen';
import { fetchProducts } from '../../../utilities/graphql/queries';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { QuickViewOverlay } from '../../shared/quickView/quickViewOverlay/QuickViewOverlay';
import { useNavActionContext } from '../../../utilities/context/dynamic/NavActionContext';
import logger from '../../../utilities/logger';

type Viewpoint = 'mobile' | 'tablet' | 'desktop';
type HotspotPosition = 'left' | 'right' | 'top' | 'bottom';

const positionStyles = {
  left: css`
    text-align: right;
    right: 38px;
    top: -21px;

    &::before {
      border-right: 0 none;
      border-left: 6px solid ${colors.WHITE};
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      right: -6px;
      top: 17px;
    }
  `,

  right: css`
    left: 25px;
    top: -20px;

    &::before {
      border-bottom: 4px solid transparent;
      border-right: 5px solid ${colors.WHITE};
      border-top: 4px solid transparent;
      top: 17px;
      left: -5px;
    }
  `,

  top: css<{ $leftPx: number }>`
    left: -${({ $leftPx }) => $leftPx + 4}px;
    bottom: 36px;

    &::before {
      left: 50%;
      bottom: -6px;
      border-top: 6px solid ${colors.WHITE};
      border-right: 5px solid transparent;
      border-bottom: 0 none;
      border-left: 5px solid transparent;
    }
  `,

  bottom: css<{ $leftPx: number }>`
    left: -${({ $leftPx }) => $leftPx + 4}px;
    top: 26px;

    &::before {
      left: 50%;
      top: -6px;
      border-top: 0 none;
      border-right: 5px solid transparent;
      border-bottom: 6px solid ${colors.WHITE};
      border-left: 5px solid transparent;
    }
  `,
};

const S = {
  Hotspots: styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 5;
  `,

  Hotspot: styled.div<{ $viewpoint: Viewpoint }>`
    position: relative;
    height: 100%;
    width: 100%;
    display: ${({ $viewpoint }) => ($viewpoint === 'mobile' ? 'block' : 'none')};

    @media ${media.greaterThan('sm')} {
      display: ${({ $viewpoint }) => ($viewpoint === 'tablet' ? 'block' : 'none')};
    }

    @media ${media.greaterThan('lg')} {
      display: ${({ $viewpoint }) => ($viewpoint === 'desktop' ? 'block' : 'none')};
    }
  `,

  HotspotWrapper: styled.span<{ $left: number; $top: number }>`
    opacity: 1;
    position: absolute;
    left: ${({ $left }) => $left}%;
    top: ${({ $top }) => $top}%;
    transform: translate3d(0, 0, 0);

    &::before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      width: 26px;
      height: 26px;
      margin: -13px 0 0 -13px;
      border-radius: 50%;
      border: 1px solid ${colors.WHITE};
      opacity: 0;
      animation: ${animations.hotspotPulse} 10s 2s infinite cubic-bezier(0.4, 0, 1, 1) none;

      @media (prefers-reduced-motion) {
        animation: none;
      }
    }
  `,

  CampaignHotspotLink: styled.button`
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid ${colors.HOTSPOT_BORDER};
    border-radius: 50%;
    transform: scale(1);
    box-shadow: 0 0 20px 0 ${colors.HOTSPOT_BOX_SHADOW},
      inset 0 0 10px 0 ${colors.HOTSPOT_BOX_SHADOW};
    width: 24px;
    height: 24px;
    margin: -12px 0 0 -12px;
    display: block;
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), border 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &::before {
      height: 2px;
      width: 10px;
      margin-top: -1px;
      margin-left: -5px;
    }

    &::after {
      width: 2px;
      height: 10px;
      margin-top: -5px;
      margin-left: -1px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: 50% 0 0 50%;
      background: ${colors.WHITE};
      transform: translate3d(0, 0, 0);
      transition: all 0.25s ease 0.25s;
      transform-origin: 50%;
    }

    &:hover,
    &:focus {
      transform: scale(1.1);
      border-color: ${colors.WHITE};

      &::before,
      &::after {
        transition-duration: 0.3s;
        transform: rotate(-180deg);
      }

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  Tooltip: styled.div<{ $position: HotspotPosition; $leftPx: number; $show?: boolean }>`
    pointer-events: none;
    max-width: 220px;
    min-width: 150px;
    padding: 4px 8px;
    font-size: 13px;
    background-color: ${colors.WHITE};
    border-radius: 2px;
    box-shadow: 0 0 4px 0 ${colors.FIBER_RANKING_BG};
    color: ${colors.NERO_GREY};
    position: absolute;
    display: ${({ $show }) => ($show ? 'block' : 'none')};

    ${({ $position }) => {
      switch ($position) {
        case 'left':
          return positionStyles.left;
        case 'right':
          return positionStyles.right;
        case 'top':
          return positionStyles.top;
        default:
          return positionStyles.bottom;
      }
    }};

    &::before {
      content: '';
      position: absolute;
    }
  `,

  HotspotProductName: styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,

  HotspotProductPrice: styled.div`
    font-weight: 700;
  `,

  BaseHotspotLink: styled(NextLink)<{ $left: number; $top: number }>`
    position: absolute;
    left: ${({ $left }) => $left}%;
    top: ${({ $top }) => $top}%;
    width: 28px;
    height: 28px;
    color: ${colors.NERO_GREY};
    background: ${colors.HOTSPOT_WHITE};
    box-shadow: 0 0 30px 0 ${colors.FIBER_RANKING_BG};
    border-radius: 50%;
    transition: all 0.25s ease 0.25s;
    transform-origin: 50%;

    &:hover,
    &:focus {
      transform: scale(1.2);
      background: ${colors.NERO_GREY};

      &::before {
        color: ${colors.WHITE};
      }
    }

    &::before {
      transition: all 0.25s ease 0.25s;
      transform-origin: 50%;
      font-family: gstar-icons;
      content: '\\F174';
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -5px;
      color: ${colors.NERO_GREY};
      font-size: 11px;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
};

export const Hotspots = ({ mobile, tablet, desktop }: HotspotsImage): ReactElement => {
  // To update this variable to dynamic to decide which style hotspot to render
  const isBaseStyle = false;
  const [hotspotProducts, setHotspotProducts] = useState<ProductsProduct[] | null>();
  const [hotspotProduct, setHotspotProduct] = useState<ProductsProduct>();
  const [index, setIndex] = useState('');
  const [leftPx, setLeftPx] = useState(0);
  const [showQuickView, setShowQuickView] = useState(false);
  const [position, setPosition] = useState<HotspotPosition>('bottom');

  const { locale } = useAppContext();
  const { sideNavOpened } = useNavActionContext();

  const hotspots = useMemo(
    () => [
      {
        source: mobile?.metadata?.metadata?.hotSpots?.hotSpots?.list,
        viewpoint: 'mobile' as Viewpoint,
      },
      {
        source: tablet?.metadata?.metadata?.hotSpots?.hotSpots?.list,
        viewpoint: 'tablet' as Viewpoint,
      },
      {
        source: desktop?.metadata?.metadata?.hotSpots?.hotSpots?.list,
        viewpoint: 'desktop' as Viewpoint,
      },
    ],
    [desktop, mobile, tablet]
  );

  useEffect(() => {
    try {
      const breakpointHotspot = hotspots.filter(hotspot => hotspot.source).at(0);
      const hotspotProductCodes = breakpointHotspot?.source?.map(source => source.target);

      if (!hotspotProductCodes?.length) {
        return;
      }

      (async () => {
        const { products } = await fetchProducts(locale, hotspotProductCodes);

        if (!products) {
          return;
        }

        setHotspotProducts(products as ProductsProduct[]);
      })();
    } catch (e) {
      logger.warn(e);
    }
  }, [locale, hotspots]);

  const selectHotspotProduct = (code: string, hotspotIndex: string) => {
    const product = hotspotProducts?.find(p => p.baseProduct?.toLowerCase() === code.toLowerCase());

    setHotspotProduct(product);
    setIndex(hotspotIndex);
  };

  const resetHotspot = () => {
    const hotspotWrapper = document.getElementById(`hotspot-wrapper-${index}`);
    const hotspotTooltip = hotspotWrapper?.querySelector('.hotspot-tooltip');

    if (hotspotTooltip) {
      gsap.to(hotspotTooltip, {
        duration: 0.2,
        autoAlpha: 0,
        ease: 'power2.out',
        overwrite: true,
        x: 0,
        y: 0,
      });
    }

    setIndex('');
  };

  useEffect(() => {
    if (index) {
      const hotspotWrapper = document.getElementById(`hotspot-wrapper-${index}`);

      if (hotspotWrapper) {
        const rect = hotspotWrapper.getBoundingClientRect();
        const hotspotTooltip = hotspotWrapper.querySelector('.hotspot-tooltip');
        const width = window.innerWidth;
        const height = window.innerHeight;
        const horizontal =
          (rect.left - (sideNavOpened ? 250 : 0)) / (width - (sideNavOpened ? 250 : 0));
        let x = 0;
        let y = 0;

        switch (true) {
          case horizontal <= 0.2:
            setPosition('right');
            x = 4;
            break;
          case horizontal <= 0.8:
            setPosition('left');
            x = -4;
            break;
          case rect.top / height > 0.5:
            setPosition('top');
            setLeftPx((hotspotTooltip?.clientWidth || 0) / 2);
            y = -4;
            break;
          default:
            setPosition('bottom');
            setLeftPx((hotspotTooltip?.clientWidth || 0) / 2);
            y = 4;
        }

        gsap.to(hotspotTooltip, {
          duration: 0.3,
          autoAlpha: 1,
          ease: 'power2.out',
          overwrite: true,
          x,
          y,
        });
      }
    }
  }, [index, sideNavOpened]);

  return mobile?.metadata?.metadata?.hotSpots?.hotSpots?.list ||
    tablet?.metadata?.metadata?.hotSpots?.hotSpots?.list ||
    desktop?.metadata?.metadata?.hotSpots?.hotSpots?.list ? (
    <>
      <S.Hotspots>
        {hotspots.map((hotspot, i) =>
          hotspot.source ? (
            <S.Hotspot key={i} $viewpoint={hotspot.viewpoint}>
              {hotspot.source.map((hotspotLink, j) => {
                const productLink = linkTargetRegex.test(hotspotLink.target)
                  ? `/shop/product/${hotspotLink.target.toUpperCase()}`
                  : hotspotLink.target.toUpperCase();

                return isBaseStyle ? (
                  <S.BaseHotspotLink
                    key={j}
                    href={productLink}
                    ariaLabel="hotspot-link"
                    $left={(hotspotLink.points?.x || 0) * 100}
                    $top={(hotspotLink.points?.y || 0) * 100}
                  />
                ) : (
                  hotspotProducts?.length && position && (
                    <S.HotspotWrapper
                      key={j}
                      $left={(hotspotLink.points?.x || 0) * 100}
                      $top={(hotspotLink.points?.y || 0) * 100}
                      id={`hotspot-wrapper-${i}-${j}`}
                    >
                      <S.CampaignHotspotLink
                        onMouseEnter={() => selectHotspotProduct(hotspotLink.target, `${i}-${j}`)}
                        onMouseLeave={resetHotspot}
                        onClick={() => setShowQuickView(true)}
                        aria-label="hotspot-link"
                      />
                      <S.Tooltip
                        className="hotspot-tooltip"
                        $position={position}
                        $leftPx={leftPx}
                        $show={hotspotProduct && index === `${i}-${j}`}
                      >
                        <S.HotspotProductName>{hotspotProduct?.name}</S.HotspotProductName>

                        <S.HotspotProductPrice>
                          {hotspotProduct?.price?.formattedValue}
                        </S.HotspotProductPrice>
                      </S.Tooltip>
                    </S.HotspotWrapper>
                  )
                );
              })}
            </S.Hotspot>
          ) : (
            <Fragment key={i} />
          )
        )}
      </S.Hotspots>
      {hotspotProduct && showQuickView && (
        <QuickViewOverlay
          code={(hotspotProduct as Product).code || (hotspotProduct as Product).baseProduct}
          product={hotspotProduct as Product}
          onClose={() => setShowQuickView(false)}
        />
      )}
    </>
  ) : (
    <></>
  );
};
