import type { ReactElement, MouseEvent } from 'react';
import { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import Image from 'next/legacy/image';
import { colors, media } from '../../core/styles';
import { isTouchable } from '../../../../utilities/device';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import type { Color, StyleVariant } from '../../../../utilities/graphql/codegen';
import { useStyleVariantsQuery } from '../../../../utilities/graphql/codegen';
import { styleVariantsLimitForSSR } from '../../../../utilities/constants';
import { imageBlurData } from '../../../../utilities/constants/base64Images';
import type { AnimationDirection } from '../../../pdp/productDetail/productImageGallery/ProductImageGallery';
import { createQueryParams } from '../../../../utilities/parsers';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';

export interface ProductStyleVariantsStateProps {
  colorLabel: string;
  hoveredVariantIndex?: number;
  setHoveredVariantIndex?: (index: number) => void;
  color?: Color | null;
  availableStyleVariantsSorted?: (StyleVariant | null)[] | null;
  code?: string;
}

const S = {
  ColorWrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ColorTitle: styled.p`
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
    text-transform: none;
    color: ${colors.RAVEN_GREY};
    font-weight: 700;

    @media ${media.greaterThan('sm')} {
      margin-bottom: 8px;
    }
  `,
  ColorInfo: styled.div`
    color: ${colors.BLUE_CHARCOAL};
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
    text-transform: none;

    @media ${media.greaterThan('sm')} {
      margin-bottom: 8px;
    }
  `,
  ColorLabel: styled.strong`
    color: ${colors.RAVEN_GREY};
    vertical-align: bottom;
  `,
  ColorDescription: styled.span`
    color: ${colors.BLUE_CHARCOAL};
    margin-left: 7px;
  `,
  StyleVariantsColorTitle: styled.div`
    position: relative;
    margin-bottom: 12px;

    @media ${media.greaterThan('sm')} {
      margin: 0 12px 8px 0;
    }
  `,
  StyleVariantsColorTitleContainer: styled.div<{ $touchable: boolean }>`
    position: relative;
    width: 100%;
    height: 31px;
    scroll-behavior: smooth;
    scrollbar-width: none;
    overflow-x: ${({ $touchable }) => ($touchable ? 'auto' : 'hidden')};
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0;
    }

    @media ${media.greaterThan('sm')} {
      width: 100%;
    }
  `,
  StyleVariantsColorTitleInner: styled.div<{ $itemCount: number }>`
    display: grid;
    grid-template-columns: repeat(${({ $itemCount }) => $itemCount}, 24px);
    grid-gap: 16px;
    position: absolute;
  `,
  StyleVariantsColorTitleInnerItemWrapper: styled.div<{ $isSelected: boolean }>`
    ${({ $isSelected }) =>
      $isSelected &&
      css`
        padding-bottom: 4px;
        border-bottom: 3px solid ${colors.NERO_GREY};
      `}
  `,
  StyleVariantsColorTitleInnerItem: styled.div`
    width: 24px;
    height: 24px;
    position: relative;
    border-radius: 50%;
    overflow: visible;
    border: none;
    box-sizing: border-box;
    background-color: transparent;
    transition: 0.3s background-color linear;
    clip-path: circle(50%);

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
  StyleVariantsColorTitleAnchor: styled.a<{ $isSelected: boolean }>`
    display: inline-block;
    width: 24px;
    height: 24px;
    position: relative;

    img {
      opacity: ${({ $isSelected }) => ($isSelected ? '0.8' : '1')};
    }
  `,
};

const SCROLL_FPS = 60;
const INTERVAL = 1000 / SCROLL_FPS;

export const QuickViewProductColor = ({
  colorLabel,
  hoveredVariantIndex,
  setHoveredVariantIndex,
  color,
  availableStyleVariantsSorted,
  code,
}: ProductStyleVariantsStateProps): ReactElement => {
  const { accessibilityLabels } = useStaticContext();
  const carouselRef = useRef<HTMLDivElement>(null);
  const carouselInnerRef = useRef<HTMLDivElement>(null);

  const [touchable, setTouchable] = useState(false);
  const [animationDirection, setAnimationDireaction] = useState<AnimationDirection | null>(null);
  const { locale } = useAppContext();

  let styleVariants = availableStyleVariantsSorted;

  useEffect(() => {
    setTouchable(isTouchable());
  }, []);

  const { baseSiteId } = createQueryParams(locale);
  const { loading, error, data } = useStyleVariantsQuery({
    variables: {
      baseSiteId,
      locale,
      code,
    },
    skip: availableStyleVariantsSorted?.length !== styleVariantsLimitForSSR,
    ssr: false,
  });

  if (!error && !loading && data) {
    styleVariants = data.product.availableStyleVariantsSorted;
  }

  useEffect(() => {
    let timerId = 0;
    const animate = () => {
      if (carouselRef.current && carouselInnerRef.current) {
        if (carouselRef.current.scrollWidth >= carouselRef.current.offsetWidth) {
          if (animationDirection === 'right') {
            carouselInnerRef.current.style.left = `${Math.max(
              carouselInnerRef.current.offsetLeft - 1,
              carouselRef.current.offsetWidth - carouselInnerRef.current.offsetWidth
            )}px`;
          } else {
            carouselInnerRef.current.style.left = `${Math.min(
              carouselInnerRef.current.offsetLeft + 1,
              0
            )}px`;
          }
        }
      }
    };

    if (animationDirection) {
      timerId = window.setInterval(animate, INTERVAL);
    }

    return () => {
      if (timerId !== 0) {
        clearInterval(timerId);
      }
    };
  }, [animationDirection]);

  const convertImageUrl = (url: string) => url.replace('{{dimensions}}', 'h_136');

  return (
    <S.ColorWrapper data-cs-capture="">
      <S.ColorInfo>
        <S.ColorLabel data-testid="color-label">{colorLabel}</S.ColorLabel>
        <S.ColorDescription data-testid="color-description">
          {hoveredVariantIndex && hoveredVariantIndex > 0 && styleVariants
            ? styleVariants[hoveredVariantIndex]?.color
            : color?.description}
        </S.ColorDescription>
      </S.ColorInfo>
      {(styleVariants?.length || 0) > 1 && (
        <S.StyleVariantsColorTitle>
          <S.StyleVariantsColorTitleContainer
            ref={carouselRef}
            $touchable={touchable}
            onMouseOver={(e: MouseEvent) => {
              const containerRect = carouselRef.current?.getBoundingClientRect();
              const innerRect = carouselInnerRef.current?.getBoundingClientRect();

              if (containerRect && innerRect && containerRect.width < innerRect.width) {
                const x = e.clientX - (containerRect.left || 0);

                if (x <= containerRect.width * 0.3) {
                  setAnimationDireaction('left');
                } else if (x >= containerRect.width * 0.7) {
                  setAnimationDireaction('right');
                } else {
                  setAnimationDireaction(null);
                }
              } else {
                setAnimationDireaction(null);
              }
            }}
            onMouseLeave={() => setAnimationDireaction(null)}
          >
            <S.StyleVariantsColorTitleInner
              data-testid="style-carousel"
              $itemCount={styleVariants?.length || 0}
              ref={carouselInnerRef}
            >
              {styleVariants?.map((item, index) => (
                <S.StyleVariantsColorTitleInnerItemWrapper
                  key={index}
                  $isSelected={hoveredVariantIndex === index}
                >
                  <S.StyleVariantsColorTitleInnerItem
                    data-testid="style-carousel-item"
                    onFocus={() => setHoveredVariantIndex && setHoveredVariantIndex(index)}
                    onMouseEnter={() => setHoveredVariantIndex && setHoveredVariantIndex(index)}
                    onMouseLeave={() => setHoveredVariantIndex && setHoveredVariantIndex(0)}
                  >
                    <Link
                      href={item?.productUrl ?? ''}
                      passHref
                      aria-label={accessibilityLabels?.quickView?.linkToStyleVariant}
                    >
                      <S.StyleVariantsColorTitleAnchor
                        $isSelected={hoveredVariantIndex === index}
                        aria-label={`${item?.name} ${item?.color}`}
                      >
                        <Image
                          src={convertImageUrl(item?.images?.thumbnail ?? '')}
                          alt={item?.images?.thumbnailAltText ?? ''}
                          blurDataURL={imageBlurData}
                          placeholder="blur"
                          layout="fill"
                          objectFit="cover"
                          sizes="25vw"
                        />
                      </S.StyleVariantsColorTitleAnchor>
                    </Link>
                  </S.StyleVariantsColorTitleInnerItem>
                </S.StyleVariantsColorTitleInnerItemWrapper>
              ))}
            </S.StyleVariantsColorTitleInner>
          </S.StyleVariantsColorTitleContainer>
        </S.StyleVariantsColorTitle>
      )}
    </S.ColorWrapper>
  );
};
