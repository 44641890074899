import type { ReactNode } from 'react';
import { S } from './styles';
import type { RecentlyViewedProduct } from '../../../../utilities/helpers';

export const TileLegacySignings = ({
  singleProduct,
  signingEnabled,
  quickViewAction,
}: {
  singleProduct: RecentlyViewedProduct;
  signingEnabled: boolean;
  quickViewAction: ReactNode;
}) => {
  if (!signingEnabled) {
    return <></>;
  }

  return (
    <S.LabelArea>
      <S.PercentArea>
        {singleProduct.signings
          ?.filter(signing => signing?.discountSigning)
          .map(signing => (
            <S.Percent
              key={signing?.label}
              component="span"
              $backgroundColor={signing?.productSigningBackgroundColour}
              $textColor={signing?.productSigningTextColour}
              className={`${signing?.plpPositionClass ?? ''} ${signing?.labelCssClasses ?? ''}`}
              testId="product-tile-discount-signing"
              data-cs-capture=""
            >
              {signing?.label}
            </S.Percent>
          ))}

        {quickViewAction}
      </S.PercentArea>

      {singleProduct.signings
        ?.filter(signing => !signing?.discountSigning && !!signing?.label)
        .map(signing => (
          <S.Label
            key={signing?.label}
            variant="subtitle"
            component="p"
            alignment="center"
            color={signing?.productSigningTextColour || ''}
            $backgroundColor={signing?.productSigningBackgroundColour}
            testId="product-tile-signing"
            data-cs-capture=""
            data-code={signing?.code}
          >
            {signing?.label}
          </S.Label>
        ))}
    </S.LabelArea>
  );
};
