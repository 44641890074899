import styled from 'styled-components';
import Link from 'next/link';
import { colors, media } from '../../../../shared/core/styles';
import type { RRPTooltipMessage } from '../../../../../amplienceTypes/schemas/imported/product-price-schema';

const S = {
  Tooltip: styled.div<{ $position?: number }>`
    font-size: 13px;
    z-index: 45;
    border: 1px solid ${colors.DISABLED_GREY};
    border-radius: 5px;
    overflow: hidden;
    top: 160%;
    width: 325px;
    transform: ${({ $position }) => `translate(-${$position}%)`};
    background-color: ${colors.WHITE};
    box-shadow: 0 0 4px 0 ${colors.FIBER_RANKING_BG};
    position: absolute;
    display: block;

    @media ${media.lessThan('md')} {
      width: 280px;
    }
  `,
  TipContent: styled.div`
    width: auto;
    min-width: auto;
    padding: 15px;
    background: ${colors.WHITE};
    font-style: normal;
    font-size: 12px;
    color: ${colors.BLUE_CHARCOAL};
  `,
  TipDescription: styled.p`
    color: ${colors.BLUE_CHARCOAL};
    margin: 0;
    line-height: 16px;
    padding-right: 24px;

    a {
      color: ${colors.SUCCESS_BLUE};
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  `,
  Close: styled.button`
    color: ${colors.BLACK};
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 3px;
    width: 24px;
    height: 24px;
    padding: 0;
    z-index: 52;
    font-size: 12px;
    outline: 0;
    border: none;
  `,
  IconClose: styled.i`
    &::before {
      content: '\\F13E';
      font-family: gstar-icons;
      font-style: normal;
    }
  `,
};

export const ProductPriceRrpTooltip = ({
  onClick,
  content,
  position,
}: {
  onClick: () => void;
  content: RRPTooltipMessage;
  position: number;
}) => {
  const { message, link } = content;

  return (
    <S.Tooltip $position={position}>
      <S.TipContent data-testid="rrp-message-box">
        <S.TipDescription>
          {message} {link?.url ? <Link href={link?.url}>{link?.label}</Link> : <></>}
        </S.TipDescription>
      </S.TipContent>
      <S.Close onClick={onClick} data-testid="rrp-message-box-close">
        <S.IconClose />
      </S.Close>
    </S.Tooltip>
  );
};
