import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { Dialog } from '../../core/dialog/Dialog';
import { colors, media } from '../../core/styles';
import { Typography } from '../../core/typography/Typography';
import type { Product } from '../../../../utilities/graphql/codegen';
import { SizeType, useProductDynamicDataQuery } from '../../../../utilities/graphql/codegen';
import { ProductPrice } from '../../../pdp/productDetail/productDetailControl/productPrice/ProductPrice';
import { QuickViewProductImage } from './QuickViewProductImage';
import { SizeSelector } from './sizeSelector/SizeSelector';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { ProductAddActions } from '../../../pdp/productDetail/productDetailControl/ProductAddActions';
import { NextLink } from '../../core/link/NextLink';
import { QuickViewProductColor } from './QuickViewProductColor';
import { ProductOverlayDialog } from '../../../pdp/productDetail/productDetailControl/productOverlayDialog/ProductOverlayDialog';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { createQueryParams } from '../../../../utilities/parsers';
import { addProductToRecentlyViewedLocalStorage } from '../../../../utilities/helpers';

export interface QuickViewOverlayProps {
  onClose: () => void;
  product: Product;
  algoliaAddToCartEventName?: string;
  code: string;
}

const S = {
  Dialog: styled(Dialog)`
    position: relative;
    height: 100%;
    max-width: 100%;
    overflow-y: auto;
    display: flex;

    @media ${media.greaterThan('sm')} {
      max-width: 750px;
      height: 500px;
    }
  `,
  ProductImageArea: styled.div`
    cursor: url('/_fes/${process.env.BUILD_TIME}/img/zoom/zoom-in-cursor.cur') 18 18, default;
    width: 50%;
  `,

  ProductInfoArea: styled.div`
    background: ${colors.WHITE};
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    width: 50%;
    box-sizing: border-box;
  `,

  Name: styled(Typography)`
    text-transform: none;
    line-height: 1em;
    margin: 35px 0 15px;

    @media ${media.greaterThan('md')} {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 50px;
      font-size: 24px;
      font-weight: 400;
    }
  `,

  Intro: styled(Typography)`
    font-weight: 400;
    font-size: 14px;
    line-height: 1em;
    text-transform: none;
  `,

  AddedToYourCart: styled(Typography)`
    color: ${colors.BLACK};
    height: 40px;
    font-weight: 700;
    margin-bottom: 8px;
  `,

  Description: styled(Typography)`
    margin-top: 30px;
    padding-top: 4px;
    padding-bottom: 13px;
    color: ${colors.ACCESSIBILITY_GREY};
    max-height: 100px;
    overflow: hidden;
  `,

  Footer: styled.div`
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0 30px;
    height: 82px;
    background: ${colors.WHITE};

    &::before {
      content: '';
      position: absolute;
      top: -32px;
      right: 0;
      left: 0;
      height: 32px;
      background: transparent
        linear-gradient(to bottom, rgba(255 255 255 / 0%) 0, ${colors.WHITE} 75%) repeat scroll 0 0;
    }

    a {
      display: block;
      margin: 30px 0 10px;

      &::after {
        font-family: gstar-icons;
        content: '\\F111';
        margin: 0 0 0 4px;
        font-size: 0.7em;
        line-height: 0;
        display: inline-block;
        font-style: normal;
        font-weight: 400;
        text-decoration: inherit;
        width: 1em;
        text-align: center;
        vertical-align: middle;
        font-variant: normal;
        text-transform: none;
      }
    }
  `,

  NextLink: styled(NextLink)`
    color: ${colors.NEON_BLUE};
    font-weight: 700;
    font-size: 13px;
    margin-left: 4px;
  `,

  Link: styled.a`
    text-decoration: none;
    color: ${colors.NERO_GREY};
    font-weight: 700;
    font-size: 13px;
    margin-left: 4px;
  `,
};

export const QuickViewOverlay = ({
  code,
  onClose,
  product,
  algoliaAddToCartEventName,
}: QuickViewOverlayProps): ReactElement => {
  const { t } = useTranslation('common', { keyPrefix: 'globalQuickView' });

  const { locale, country } = useAppContext();
  const { quickViewContent, accessibilityLabels } = useStaticContext();
  const [firstSize, setFirstSize] = useState('');
  const [secondSize, setSecondSize] = useState<string | undefined | null>('');
  const [sizeCode, setSizeCode] = useState('');
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [addedToCart, setAddedToCart] = useState(false);
  const [dlgOpen, setDlgOpen] = useState(false);
  let dynamicProduct: Product = product;

  const { baseSiteId } = createQueryParams(locale);
  const { loading, error, data } = useProductDynamicDataQuery({
    variables: {
      baseSiteId,
      locale,
      code,
    },
    ssr: false,
  });

  if (!loading && !error) {
    dynamicProduct = { ...product, ...(data?.productDynamicData as Product) };
  }

  addProductToRecentlyViewedLocalStorage(country, product);

  useEffect(() => {
    const selectedSize = dynamicProduct.sizeInformation?.sizes?.find(size =>
      dynamicProduct.sizeInformation?.sizeType === SizeType.NumericDouble
        ? size?.value === firstSize + secondSize
        : size?.value === firstSize
    );

    setSizeCode(prev => selectedSize?.code || prev);
  }, [
    firstSize,
    dynamicProduct.sizeInformation?.sizeType,
    dynamicProduct.sizeInformation?.sizes,
    secondSize,
  ]);

  useEffect(() => {
    if (dynamicProduct.sizeInformation?.sizes?.length === 1) {
      setFirstSize(dynamicProduct.sizeInformation.sizes[0]?.gridValue1 || '');
      setSecondSize(dynamicProduct.sizeInformation.sizes[0]?.gridValue2 || '');
    }
  }, [dynamicProduct.sizeInformation?.sizes]);

  return (
    <S.Dialog
      onClose={onClose}
      overlayColor={colors.DIALOG_DARK_GREY}
      ariaLabel="stock-locator-overlay"
      testId="quick-view-overlay"
      position="top"
      alignItemCenter
    >
      <S.ProductImageArea>
        <QuickViewProductImage
          simplifiedImages={dynamicProduct.simplifiedImages}
          productStyleVariantInfo={
            hoveredIndex > 0 && dynamicProduct.availableStyleVariantsSorted
              ? dynamicProduct.availableStyleVariantsSorted[hoveredIndex]
              : undefined
          }
          shouldUseDarkBackgroundImgs={!!dynamicProduct.shouldUseDarkBackgroundImgs}
        />
      </S.ProductImageArea>
      <S.ProductInfoArea>
        <S.Name
          component="h2"
          variant="h3"
          color={colors.NERO_GREY}
          testId="quick-view-product-name"
        >
          {(hoveredIndex > 0 && dynamicProduct.availableStyleVariantsSorted
            ? dynamicProduct.availableStyleVariantsSorted[hoveredIndex]?.name
            : undefined) || dynamicProduct.name}
        </S.Name>
        <ProductPrice
          _meta={{ schema: '' }}
          price={dynamicProduct.price}
          fromPrice={dynamicProduct.fromPrice}
          productStyleVariantInfo={
            hoveredIndex > 0 && dynamicProduct.availableStyleVariantsSorted
              ? dynamicProduct.availableStyleVariantsSorted[hoveredIndex]
              : undefined
          }
          formattedLowestPrice={
            dynamicProduct?.formattedLowestPrice || product?.formattedLowestPrice
          }
          {...quickViewContent?.productPrice?.content}
        />
        <QuickViewProductColor
          colorLabel={quickViewContent?.color || t('color')}
          code={dynamicProduct.code}
          color={dynamicProduct.color}
          hoveredVariantIndex={hoveredIndex}
          setHoveredVariantIndex={setHoveredIndex}
          availableStyleVariantsSorted={dynamicProduct.availableStyleVariantsSorted}
        />
        {(dynamicProduct.sizeInformation?.sizes?.length || 0) > 1 &&
          dynamicProduct.purchasable &&
          !dynamicProduct.comingSoon && (
            <SizeSelector
              product={dynamicProduct}
              sizeLabel={quickViewContent?.size || t('size')}
              waistLabel={quickViewContent?.waist || t('waist')}
              selectWaistAndLengthLabel={
                quickViewContent?.selectWaistAndLength || t('selectWaistAndLength')
              }
              firstSize={firstSize}
              secondSize={secondSize || undefined}
              setFirstSize={(val: string) => {
                setFirstSize(val);
                setAddedToCart(false);
              }}
              setSecondSize={(val: string | undefined | null) => {
                setSecondSize(val);
                setAddedToCart(false);
              }}
              onClose={onClose}
            />
          )}
        {addedToCart ? (
          <S.AddedToYourCart>
            {quickViewContent?.productHasBeenAddedToYour || t('productHasBeenAddedToYour')}
            <S.NextLink aria-label={accessibilityLabels?.quickView?.linkToCart} href="/cart">
              {quickViewContent?.cart || t('cart')}
            </S.NextLink>
          </S.AddedToYourCart>
        ) : (
          <ProductAddActions
            _meta={{ schema: '' }}
            sizeCode={sizeCode}
            gridValues={{
              gridValue1: firstSize,
              gridValue2: secondSize || '',
            }}
            product={dynamicProduct}
            addToCartTxtV2={quickViewContent?.addToBag || t('addToBag')}
            addingToCartTxtV2={quickViewContent?.loading || t('loading')}
            sizeErrorTitle={quickViewContent?.sizeErrorTitle || t('sizeErrorTitle')}
            sizeErrorTxtV2={quickViewContent?.sizeErrorDescription || t('sizeErrorDescription')}
            wishlistButtonType="QuickView"
            setDlgOpen={setDlgOpen}
            onAdded={() => setAddedToCart(true)}
            fromQuickView
            algoliaAddToCartEventName={algoliaAddToCartEventName}
            aria-label={quickViewContent?.addToBag || t('addToBag')}
          />
        )}
        {!dynamicProduct.isGiftCard && !dynamicProduct.oneSize && dlgOpen && (
          <ProductOverlayDialog
            onClose={() => {
              setDlgOpen(false);
            }}
            waistLabel={quickViewContent?.waist || t('waist')}
            lengthLabel={quickViewContent?.length || t('length')}
            sizeLabel={quickViewContent?.size || t('size')}
            product={dynamicProduct}
            _meta={{ schema: '' }}
            {...quickViewContent?.overlay?.content}
          />
        )}
        <S.Description testId="quick-view-product-description">
          {dynamicProduct.featuresIntro}
        </S.Description>
        <S.Footer>
          <S.Link
            href={dynamicProduct.url || ''}
            data-testid="quick-view-product-link"
            aria-label={quickViewContent?.viewAllProductDetail || t('viewAllProductDetail')}
          >
            {quickViewContent?.viewAllProductDetail || t('viewAllProductDetail')}
          </S.Link>
        </S.Footer>
      </S.ProductInfoArea>
    </S.Dialog>
  );
};
