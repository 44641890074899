import type { KeyboardEvent } from 'react';
import { useState, useRef, useEffect, useCallback, Fragment, useMemo } from 'react';
import styled, { css } from 'styled-components';
import type { SizeButtonStyle } from '../../../../../utilities/constants';
import { useAppContext } from '../../../../../utilities/context/static/AppContext';
import type { Product } from '../../../../../utilities/graphql/codegen';
import { SizeType } from '../../../../../utilities/graphql/codegen';
import { checkSizeFormatted } from '../../../../../utilities/parsers';
import { colors } from '../../../core/styles';
import { Typography } from '../../../core/typography/Typography';
import { useStaticContext } from '../../../../../utilities/context/static/StaticContext';
import { sizeButtonStyles } from '../../../common/styles';

const styles = {
  IconButton: css`
    display: inline-block;
    outline: 0;
    border: 0;
    box-sizing: border-box;
    font-weight: 700;
    cursor: pointer;
    color: white;
    padding: 0 6px;

    &::before {
      font-family: gstar-icons;
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      line-height: 1em;
      text-decoration: inherit;
      width: 1em;
      margin-right: 0.2em;
      text-align: center;
      vertical-align: middle;
      margin-left: 0.2em;
      -webkit-font-smoothing: antialiased !important;
    }
  `,
};

const S = {
  SizeWrapper: styled.div`
    padding-bottom: 8px;
    display: flex;
    gap: 10px;
  `,

  Sizes: styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  `,

  Size: styled.button<{ $selected: boolean; $btnStyle: SizeButtonStyle }>`
    display: flex;
    font-weight: bold;
    justify-content: center;
    margin-right: ${({ $btnStyle }) => sizeButtonStyles[$btnStyle].marginRight};
    margin-bottom: ${({ $btnStyle }) => sizeButtonStyles[$btnStyle].marginBottom};
    padding-left: ${({ $btnStyle }) =>
      $btnStyle === 'sizeRange' && sizeButtonStyles[$btnStyle].paddingLeft};
    padding-right: ${({ $btnStyle }) =>
      $btnStyle === 'sizeRange' && sizeButtonStyles[$btnStyle].paddingRight};
    align-items: center;
    cursor: pointer;
    border: ${({ $selected }) => ($selected ? '2px' : '1px')} solid ${colors.NERO_GREY};
    box-sizing: border-box;
    color: ${colors.NERO_GREY};
    font-size: ${({ $btnStyle }) => sizeButtonStyles[$btnStyle].fontSize};
    height: ${({ $btnStyle }) => sizeButtonStyles[$btnStyle].height};
    width: ${({ $btnStyle }) => sizeButtonStyles[$btnStyle].width};
    flex-direction: ${({ $btnStyle }) => sizeButtonStyles[$btnStyle].direction};
    background: ${colors.WHITE};

    &:hover,
    &:focus {
      outline: thin dotted;
    }
  `,

  SizeText: styled(Typography)`
    font-size: 11px;
    font-weight: normal;
    color: ${colors.ACCESSIBILITY_GREY};
  `,

  DoubleSizeButton: styled.button`
    border: 1px solid ${colors.NERO_GREY};
    cursor: pointer;
    padding: 0 12px;
    text-align: left;
    line-height: 42px;
    width: 100%;
    display: inline-block;
    outline: 0;
    box-sizing: border-box;
    color: ${colors.NERO_GREY};
    font-weight: 700;
    height: 44px;
    position: relative;

    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid ${colors.NERO_GREY};
      content: '';
      display: block;
      height: 0;
      margin-top: -3px;
      pointer-events: none;
      position: absolute;
      right: 12px;
      top: 50%;
      width: 0;
      z-index: 1;
    }

    &:hover,
    &:focus {
      outline: thin dotted;
    }
  `,

  DoubleSizeSelector: styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    z-index: 1;
  `,
  DoubleSizeSelectorHeader: styled.div`
    background-color: ${colors.NERO_GREY};
    box-sizing: border-box;
    display: flex;
    font-weight: 700;
    height: 54px;
    justify-content: space-between;
    padding: 0 6px;
    text-transform: uppercase;
    color: ${colors.WHITE};
    line-height: 54px;
  `,
  BtnBack: styled.button`
    ${styles.IconButton}
    &::before {
      content: '\\F10D';
    }
  `,
  BtnClose: styled.button`
    ${styles.IconButton}
    &::before {
      content: '\\F13F';
    }
  `,
  DoubleSizeSelectorContent: styled.div`
    height: calc(100% - 54px);
    overflow-y: auto;
    transform: translateZ(0);
    background: ${colors.WHITE};
  `,
  DoubleSizeSelectorContentHeading: styled.div`
    background-color: ${colors.LIGHT_BG_GREY};
    color: ${colors.ACCESSIBILITY_GREY};
    font-weight: 700;
    height: 56px;
    padding-top: 22px;
    position: sticky;
    text-transform: uppercase;
    top: 0;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
  `,
  DoubleSizeSelectorContentItemContainer: styled.div<{ $selected: boolean }>`
    height: 48px;
    padding-top: 14px;
    border-top: 1px solid ${colors.LIGHT_SIZE_BORDER_GREY};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;

    ${({ $selected }) =>
      $selected
        ? css`
            &::after {
              content: '\\F13C';
              font-family: gstar-icons;
            }
          `
        : ''}
  `,

  DoubleSizeSelectorContentItem: styled.button`
    font-weight: 400;
    height: fit-content;

    &:hover,
    &:focus {
      outline: thin dotted;
    }
  `,
};

const shortenSize = (size: string) => {
  const matches = size.toLowerCase().match(/^x{3,}/);

  if (matches) {
    return `${matches[0].length}X${size.charAt(size.length - 1)}`;
  }

  return size;
};

interface SizeSelectorProps {
  product: Product;
  sizeLabel?: string;
  waistLabel?: string;
  selectWaistAndLengthLabel?: string;
  firstSize: string;
  setFirstSize: (val: string) => void;
  secondSize?: string;
  setSecondSize: (val: string | undefined | null) => void;
  onClose: () => void;
}

export const SizeSelector = ({
  product,
  sizeLabel,
  waistLabel,
  selectWaistAndLengthLabel,
  firstSize,
  setFirstSize,
  secondSize,
  setSecondSize,
  onClose,
}: SizeSelectorProps) => {
  const { country } = useAppContext();
  const { accessibilityLabels } = useStaticContext();
  const [isOpenedDoubleSizeSelector, setIsOpenedDoubleSizeSelector] = useState(false);
  const offSetItem = useRef(0);
  const sizeButtonRefs = useRef<HTMLButtonElement[]>([]);

  const selectedSize = product.sizeInformation?.sizes?.find(
    size =>
      size?.gridValue1 === firstSize &&
      (product.sizeInformation?.sizeType !== SizeType.NumericDouble ||
        size?.gridValue2 === secondSize)
  );

  const { isSizeFormatted, sizeButtonStyle } = checkSizeFormatted(
    product.sizeInformation?.sizes || [],
    country
  );

  const isLetterSize = product.sizeInformation?.sizeType === SizeType.Letters;
  const isNumericDouble = product.sizeInformation?.sizeType === SizeType.NumericDouble;

  const sizes = useMemo(() => {
    const doubleSizePlainArray = product.sizeInformation?.groupedSizes
      ?.filter(group => group?.purchasable)
      ?.map(item => item?.sizes)
      ?.flat()
      .filter(size => size?.purchasable);
    const singleSizes = product.sizeInformation?.sizes?.filter(size => size?.purchasable);
    const sizes = isNumericDouble ? doubleSizePlainArray : singleSizes;

    return sizes;
  }, [isNumericDouble, product.sizeInformation?.groupedSizes, product.sizeInformation?.sizes]);

  const findItemIndex = useCallback(
    (gridValue1?: string, gridValue2?: string) =>
      isNumericDouble
        ? sizes?.findIndex(
            item => item?.gridValue1 === gridValue1 && item?.gridValue2 === gridValue2
          )
        : sizes?.findIndex(item => item?.gridValue1 === gridValue1),
    [isNumericDouble, sizes]
  );

  useEffect(() => {
    const index = findItemIndex(firstSize, secondSize);

    offSetItem.current = index || 0;

    const element = sizeButtonRefs.current[index || 0];

    element?.scrollIntoView({ block: 'center', inline: 'nearest' });
    element?.focus();
  }, [findItemIndex, firstSize, secondSize, isOpenedDoubleSizeSelector]);

  const renderLetterSingleSizeSelector = () => (
    <S.SizeWrapper>
      <S.Sizes data-testid="width-size-selector">
        {product.sizeInformation?.sizes
          ?.filter(size => size?.purchasable)
          .map((size, i) => (
            <S.Size
              key={i}
              $selected={size?.value === firstSize}
              onClick={() => setFirstSize(size?.value || '')}
              $btnStyle={sizeButtonStyle}
              data-cs-capture=""
              aria-label={`${accessibilityLabels?.quickView?.selectSize} ${size?.value}`}
              ref={(element: HTMLButtonElement | null) => {
                if (element) {
                  const sizeIndex = findItemIndex(size?.gridValue1, size?.gridValue2) || 0;

                  sizeButtonRefs.current[sizeIndex] = element;
                }
              }}
            >
              {shortenSize(size?.value || '')}
              {isSizeFormatted && (
                <S.SizeText variant="body" component="p" data-cs-capture="">
                  {(size?.value || '').replace(/(^.*\(|\).*$)/g, '')}
                </S.SizeText>
              )}
            </S.Size>
          ))}
      </S.Sizes>
    </S.SizeWrapper>
  );

  const renderDoubleSizeSelector = () => (
    <S.DoubleSizeSelector>
      <S.DoubleSizeSelectorHeader>
        <S.BtnBack
          aria-label={accessibilityLabels?.quickView?.closeDropDownSelector}
          onClick={() => setIsOpenedDoubleSizeSelector(false)}
        />
        {selectWaistAndLengthLabel}
        <S.BtnClose aria-label={accessibilityLabels?.quickView?.closeQuickView} onClick={onClose} />
      </S.DoubleSizeSelectorHeader>
      <S.DoubleSizeSelectorContent>
        {product.sizeInformation?.groupedSizes
          ?.filter(group => group?.purchasable)
          .map((group, index) => (
            <Fragment key={index}>
              <S.DoubleSizeSelectorContentHeading key={`group-${index}`}>
                {waistLabel} {group?.value}
              </S.DoubleSizeSelectorContentHeading>
              {group?.sizes
                ?.filter(size => size?.purchasable)
                .map((size, sizeIndex) => (
                  <S.DoubleSizeSelectorContentItemContainer
                    key={`size-${index}-${sizeIndex}`}
                    onClick={() => {
                      setFirstSize(size?.gridValue1 || '');
                      setSecondSize(size?.gridValue2);
                      setIsOpenedDoubleSizeSelector(false);
                    }}
                    $selected={firstSize === size?.gridValue1 && secondSize === size.gridValue2}
                    aria-label={`${accessibilityLabels?.quickView?.selectSize} ${size?.formattedValue}`}
                  >
                    <S.DoubleSizeSelectorContentItem
                      ref={(element: HTMLButtonElement | null) => {
                        if (element) {
                          const sizeIndex = findItemIndex(size?.gridValue1, size?.gridValue2) || 0;

                          sizeButtonRefs.current[sizeIndex] = element;
                        }
                      }}
                    >
                      {size?.formattedValue}
                    </S.DoubleSizeSelectorContentItem>
                  </S.DoubleSizeSelectorContentItemContainer>
                ))}
            </Fragment>
          ))}
      </S.DoubleSizeSelectorContent>
    </S.DoubleSizeSelector>
  );

  const renderNumericSingleSizeSelector = () => (
    <S.DoubleSizeSelector>
      <S.DoubleSizeSelectorHeader>
        <S.BtnBack
          aria-label={accessibilityLabels?.quickView?.closeDropDownSelector}
          onClick={() => setIsOpenedDoubleSizeSelector(false)}
        />
        {selectWaistAndLengthLabel}
        <S.BtnClose aria-label={accessibilityLabels?.quickView?.closeQuickView} onClick={onClose} />
      </S.DoubleSizeSelectorHeader>
      <S.DoubleSizeSelectorContent>
        {product.sizeInformation?.sizes
          ?.filter(size => size?.purchasable)
          .map((size, index) => (
            <S.DoubleSizeSelectorContentItemContainer
              key={`size-${index}`}
              onClick={() => {
                setFirstSize(size?.gridValue1 || '');
                setSecondSize(size?.gridValue2);
                setIsOpenedDoubleSizeSelector(false);
              }}
              $selected={firstSize === size?.gridValue1}
              aria-label={`${accessibilityLabels?.quickView?.selectSize} ${size?.formattedValue}`}
            >
              <S.DoubleSizeSelectorContentItem
                ref={(element: HTMLButtonElement | null) => {
                  if (element) {
                    const sizeIndex = findItemIndex(size?.gridValue1, size?.gridValue2) || 0;

                    sizeButtonRefs.current[sizeIndex] = element;
                  }
                }}
              >
                {size?.formattedValue}
              </S.DoubleSizeSelectorContentItem>
            </S.DoubleSizeSelectorContentItemContainer>
          ))}
      </S.DoubleSizeSelectorContent>
    </S.DoubleSizeSelector>
  );

  const selectNewSize = (index: number) => {
    const canSelect = isLetterSize || (!isLetterSize && isOpenedDoubleSizeSelector);

    if (sizes && canSelect) {
      setFirstSize(sizes[index]?.gridValue1);
      setSecondSize(sizes[index]?.gridValue2);

      const element = sizeButtonRefs.current[index];

      element?.scrollIntoView({ block: 'center', inline: 'nearest' });
      element?.focus();
    }
  };

  const handleKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!sizes) {
      return;
    }

    const keyCode = event.key;

    if ((isLetterSize && keyCode === 'ArrowLeft') || (!isLetterSize && keyCode === 'ArrowUp')) {
      if (!isLetterSize && isOpenedDoubleSizeSelector) {
        event.preventDefault();
      }

      const newIndex =
        offSetItem.current === -1 || offSetItem.current === 0 ? 0 : offSetItem.current - 1;

      selectNewSize(newIndex);
    } else if (
      offSetItem.current !== sizes.length - 1 &&
      ((isLetterSize && keyCode === 'ArrowRight') ||
        (!isLetterSize && keyCode === 'ArrowDown') ||
        (!isLetterSize && keyCode === 'Tab'))
    ) {
      if (!isLetterSize && isOpenedDoubleSizeSelector) {
        event.preventDefault();
      }

      selectNewSize(offSetItem.current + 1);
    } else if (event.key === 'Enter' && !isLetterSize) {
      setIsOpenedDoubleSizeSelector(false);
    }
  };

  return (
    <S.SizeWrapper onKeyDown={handleKeydown}>
      {isLetterSize ? (
        renderLetterSingleSizeSelector()
      ) : (
        <>
          <S.DoubleSizeButton
            aria-label={accessibilityLabels?.quickView?.openDropDownSelector}
            onClick={() => setIsOpenedDoubleSizeSelector(true)}
          >
            {`${sizeLabel} ${selectedSize?.formattedValue || ''}`}
          </S.DoubleSizeButton>
          {isOpenedDoubleSizeSelector &&
            (isNumericDouble ? renderDoubleSizeSelector() : renderNumericSingleSizeSelector())}
        </>
      )}
    </S.SizeWrapper>
  );
};
