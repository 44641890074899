import { S } from './TileTitleWithPrice';

type TileTitlePriceProps = {
  discountedPrice: string | undefined;
  initialPrice: string | undefined;
  originalPriceCopy: string | undefined;
};

export const TileTitlePrice = ({
  discountedPrice,
  initialPrice,
  originalPriceCopy,
}: TileTitlePriceProps) => (
  <>
    <S.PriceWrapper>
      {discountedPrice !== initialPrice && initialPrice ? (
        <S.PriceLowest30Days>
          {originalPriceCopy} {initialPrice.replace(' ', '')}
        </S.PriceLowest30Days>
      ) : (
        <></>
      )}
      {discountedPrice && discountedPrice !== initialPrice ? (
        <S.DiscountedPrice data-testid="product-tile-price">{discountedPrice}</S.DiscountedPrice>
      ) : (
        <S.InitialPrice data-testid="product-tile-price">{initialPrice}</S.InitialPrice>
      )}
    </S.PriceWrapper>
  </>
);
