/* eslint-disable @next/next/no-img-element, jsx-a11y/alt-text */
import { useState, type ImgHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { colors, media } from '../core/styles';
import { shimmerAnimation } from './styles';

const duration = css<{ $shimmerDuration?: string }>`
  ${({ $shimmerDuration }) => ($shimmerDuration ? `animation-duration: {$shimmerDuration}` : '')}
  ${({ $shimmerDuration = '' }) => (parseInt($shimmerDuration, 10) === 0 ? `display: none` : '')}
`;

const S = {
  ImageWrapper: styled.picture<{ $shimmerDuration?: string }>`
    background-color: ${colors.COLOR_GREY_200};
    position: relative;
    height: 100%;
    display: block;

    img {
      opacity: 1;
      object-fit: cover;
      width: 100%;
      height: 100%;

      @media ${media.noMotionPreference} {
        transition: opacity 200ms ease-out;
      }
    }

    /* safari fix (: */
    &::after {
      content: '';
      display: none;
    }

    &[data-loaded='false'] {
      img {
        opacity: 0;
      }

      ${shimmerAnimation};

      &::after {
        position: absolute;
        inset: 0;

        ${duration}
      }
    }
  `,
};

type ImageWithLoaderIndicatorProps = ImgHTMLAttributes<HTMLImageElement> & {
  /**
   * Controls whatever the animation should fade in after being loaded
   *
   * Set to false to display immediately
   */
  fadeInOnLoad?: boolean;
  /**
   * Controls the duration of the loading animation per iteration
   *
   * Set to `0s` to disable animation
   */
  shimmerDuration?: string;
};

export const ImageWithLoaderIndicator = ({
  fadeInOnLoad = true,
  onLoad,
  shimmerDuration,
  className,
  ...rest
}: ImageWithLoaderIndicatorProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean | undefined>(!fadeInOnLoad);

  return (
    <S.ImageWrapper className={className} $shimmerDuration={shimmerDuration} data-loaded={isLoaded}>
      <img
        {...rest}
        onLoad={result => {
          if (fadeInOnLoad) {
            // sets the flag to undefined so the `data-loaded` attribute is removed from DOM
            setIsLoaded(undefined);
          }

          onLoad?.(result);
        }}
      />
    </S.ImageWrapper>
  );
};
